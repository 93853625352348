import React from 'react'
import World from './assets/world'
import './BoostContent.scss'
import CallToActionBanner from '../CallToActionBanner/CallToActionBanner'
import { FaRegEnvelope, FaBolt } from 'react-icons/fa'
import BoostForm from '../BoostForm/BoostForm'

export class BoostContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deviceAttr: 'mobile',
    }
    if (typeof window !== 'undefined') {
      this.setAnimationInterval()
    }
  }

  setAnimationInterval() {
    setTimeout(() => {
      let switchInterval = 2000
      let i = 0
      let devices = ['desktop', 'tablet', 'mobile']
      let deviceSwitch = setInterval(() => {
        // switch code function
        i++
        if (i === devices.length) i = 0

        // switch
        this.setState({ deviceAttr: devices[i] })
      }, switchInterval)
    }, 1000)
  }

  componentDidMount() {
    setTimeout(() => {
      this.initializeGlobe()
    }, 2000)
  }

  initializeGlobe() {
    if (typeof window === 'undefined') {
      return
    }
    var d3 = window.d3
    var topojson = window.topojson
    var width = 400
    var height = 400
    if (typeof d3 === 'undefined') {
      return
    }
    let existingWorld = d3.select('svg')
    if (existingWorld) {
      existingWorld.remove()
    }
    var projection = d3
      .geoOrthographic()
      .scale(200)
      .translate([width / 2, height / 2])
      .clipAngle(90)

    var path = d3.geoPath().projection(projection)

    var λ = d3
      .scaleLinear()
      .domain([0, width])
      .range([-180, 180])

    var φ = d3
      .scaleLinear()
      .domain([0, height])
      .range([90, -90])

    var svg = d3
      .select('#worldmap')
      .append('svg')
      .style('background', '#2b8aec')
      .style('border-radius', '200px')
      .style('box-shadow', '0 2px 30px rgba(0, 0, 0, 0.2)')
      .attr('width', width)
      .attr('height', height)

    var worldData = new World()

    var top = worldData.topology

    svg
      .append('path')
      .datum(topojson.feature(top, top.objects.land))
      .attr('class', 'land')
      .style('fill', '#fff')
      .attr('d', path)

    var scrollSpeed = 50
    var current = 0
    function bgscroll() {
      current += 1
      projection.rotate([λ(current), 0])
      svg.selectAll('path').attr('d', path)
    }

    setInterval(bgscroll, scrollSpeed)
  }

  render() {
    return (
      <div className="boost-content">
        <div className="boost-hero">
          <div className="boost-name">
            <span className="highlight">
              <span className="icon-wrap">
                <FaBolt />
              </span>
              Boost
            </span>{' '}
            is your supercharged, <span className="underline">on-demand</span>{' '}
            product team.
          </div>
        </div>
        <div className="subtitle-wrap">
          <div className="boost-subtitle">
            <span>Move at the speed of innovation.</span> We'll take your
            project from design to done - or lend your team a top-notch
            developer (or five).
          </div>
        </div>
        <div className="boost-cta-wrap">
          <a href="#signup" className="btn-raised-light-blue big-shadow">
            Get Started
          </a>
        </div>
        <section className="padding-large">
          <div className="row boost-row">
            <div className="col-md-6 text-col">
              <h2>Delight your users on every device</h2>
              <p>We build beautiful web, cross-platform, and native apps.</p>
            </div>
            <div className="col-md-6 image-col">
              <div className="image-wrap">
                <img src="/alldevice_illustration.svg" />
              </div>
              <div className="animation-wrap">
                <div className="browser" data-device={this.state.deviceAttr}>
                  <div className="browser-input" />
                  <ul className="browser-controls">
                    <li />
                    <li />
                    <li />
                  </ul>
                  <div className="device-power" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="slanted-bg">
          <div className="row boost-row">
            <div className="col-md-6 col-md-push-6 text-col">
              <h2>Move the needle on Digital</h2>
              <p>
                Transform your business without the overhead of full time
                employees
              </p>
            </div>

            <div className="col-md-6 col-md-pull-6">
              <img src="/up_illustration.svg" />
            </div>
          </div>
        </div>
        <section className="padding-large no-right">
          <div className="row boost-row">
            <div className="col-md-6 text-col">
              <h2>Built to Scale</h2>
              <p>
                Cloud-powered Infrastructure and DevOps to keep your product
                snappy - built by Certified AWS Architects
              </p>
            </div>

            <div className="col-md-6 world-wrap">
              <div className="world" id="worldmap" />
            </div>
          </div>
        </section>
        <div className="slanted-bg lighter">
          <div className="row boost-row">
            <div className="col-md-6 col-md-push-6 text-col">
              <h2>Top-level dev talent without the hassle</h2>
              <p>
                Trusted by highly funded startups and large enterprises alike:
                every Boost developer is an experienced full-stack engineer with
                significant cloud experience.
              </p>
            </div>

            <div className="col-md-6 col-md-pull-6">
              <img src="/cv_illustration.svg" />
            </div>
          </div>
        </div>
        <BoostForm />
      </div>
    )
  }
}

export default BoostContent
