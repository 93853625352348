import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import BoostContent from '../components/BoostContent/BoostContent'
import SEO from '../components/SEO'

const Boost = ({ data }) => {
  const postNode = {
    title: `Boost - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="boost" customTitle />
      <Helmet>
        <title>{`Boost - ${config.siteTitle}`}</title>
        <script src="https://d3js.org/d3.v4.min.js" />
        <script src="https://d3js.org/topojson.v1.min.js" />
      </Helmet>
      <BoostContent />
    </Layout>
  )
}

export default Boost
