import React from 'react'
import './BoostForm.scss'
import { FaCheckCircle, FaFlag, FaCloud, FaPaperPlane } from 'react-icons/fa'
const axios = require('axios')

export class BoostForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hideForm: false,
    }
  }

  showThankYou() {
    this.setState({ hideForm: true })
  }

  submitForm(ev) {
    ev.preventDefault()
    console.log(
      ev.target.fullname,
      ev.target.email,
      ev.target.phone,
      ev.target.project_type,
      ev.target.budget_monthly,
      ev.target.num_devs
    )
    axios
      .post(
        'https://api.hsforms.com/submissions/v3/integration/submit/2842193/7e67ea24-5843-4ad0-947f-f28e634c607f',
        {
          fields: [
            {
              name: 'firstname',
              value: ev.target.fullname.value,
            },
            {
              name: 'email',
              value: ev.target.email.value,
            },
            {
              name: 'company',
              value: ev.target.company.value,
            },
            {
              name: 'project_type',
              value: ev.target.project_type.value,
            },
            {
              name: 'budget_monthly',
              value: ev.target.budget_monthly.value,
            },
            {
              name: 'num_devs',
              value: ev.target.num_devs.value,
            },
          ],
        }
      )
      .then(() => {
        this.showThankYou()
      })
      .catch(err => {
        console.log(err)
      })
  }

  renderFormSuccess() {
    let el
    if (this.state.hideForm) {
      el = (
        <div className="thanks-wrap">
          <div className="thanks-text">
            <div>
              Thanks for the message! We'll be in touch within the next day or
              so.
            </div>
            <div className="thanks-icon">
              <FaCheckCircle />
            </div>
          </div>
        </div>
      )
    } else {
    }
    return el
  }

  renderForm() {
    let el
    el = (
      <div>
        <form onSubmit={this.submitForm.bind(this)} id="BOOST FORM" noValidate>
          <div className="_form-content">
            <div className="_form_element _x70061606 _full_width ">
              <div className="_field-wrapper">
                <input type="text" name="fullname" placeholder="Full Name" />
              </div>
            </div>
            <div className="_form_element _x28370249 _full_width ">
              <div className="_field-wrapper">
                <input
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  required
                />
              </div>
            </div>
            <div className="_form_element _field1 _full_width ">
              <div className="_field-wrapper">
                <input type="text" name="company" placeholder="Company" />
              </div>
            </div>
            <div className="_form_element _field2 _full_width ">
              <div className="_field-wrapper select">
                <select name="project_type" required>
                  <option className="default" value="">
                    Project Type
                  </option>
                  <option value="Cloud Architecture/DevOps">
                    Cloud Architecture/DevOps
                  </option>
                  <option value="Cloud Migration/Audit">
                    Cloud Migration/Audit
                  </option>
                  <option value="Website/Web Application">
                    Website/Web Application
                  </option>
                  <option value="Mobile Application">Mobile Application</option>
                  <option value="Data Analytics">Data Analytics</option>
                </select>
              </div>
            </div>
            <div className="_form_element _field3 _full_width ">
              <div className="_field-wrapper select">
                <select name="num_devs" required>
                  <option className="default" value="">
                    Number of developers needed
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5+">5+</option>
                </select>
              </div>
            </div>
            <div className="_form_element _field4 _full_width ">
              <div className="_field-wrapper select">
                <select name="budget_monthly" required>
                  <option className="default" value="">
                    Monthly Budget
                  </option>
                  <option value="Less than $5,000">Less than $5,000</option>
                  <option value="$5,000 - $15,000">$5,000 - $15,000</option>
                  <option value="$15,000 - $25,000">$15,000 - $25,000</option>
                  <option value="$25,000+">$25,000+</option>
                </select>
              </div>
            </div>
            <div className="_button-wrapper _full_width">
              <button id="_form_1_submit" className="form-button" type="submit">
                Submit
              </button>
            </div>
            <div className="_clear-element" />
          </div>
          <div
            className="_form-thank-you"
            style={{
              display: 'none',
            }}
          />
        </form>
      </div>
    )
    return el
  }

  render() {
    return (
      <div id="signup" className="boost-form-upper-row padding-large">
        <div className="boost-form-row">
          <div className="boost-form-wrap">
            {this.state.hideForm ? this.renderFormSuccess() : this.renderForm()}
          </div>
          <div className="boost-form-text">
            <h2>Need a couple of helping hands now or sometime soon?</h2>
            <h3>
              Sign up and we'll send you a couple of our vetted Boost developers
              for review
            </h3>
            <div className="benefits-row">
              <div className="benefit-col">
                <div className="benefit-inner">
                  <div className="icon-wrap">
                    <FaFlag />
                  </div>
                  <div className="text-line">US-Based</div>
                </div>
              </div>
              <div className="benefit-col">
                <div className="benefit-inner">
                  <div className="icon-wrap">
                    <FaCloud />
                  </div>
                  <div className="text-line">Cloud Certified</div>
                </div>
              </div>
              <div className="benefit-col">
                <div className="benefit-inner">
                  <div className="icon-wrap">
                    <FaPaperPlane />
                  </div>
                  <div className="text-line">Vetted</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BoostForm
